import Vue from 'vue'
import { Tabbar, TabbarItem, Search, Icon, Swipe, SwipeItem, Toast, Grid, GridItem,dialog,Dialog, NavBar, Cell, CellGroup, Col, Row, Form, Field, Button, Image as VanImage, Popup, IndexBar, IndexAnchor, Sticky, DropdownMenu, DropdownItem, DatetimePicker, Picker, RadioGroup, Radio, Stepper, PullRefresh, List, Pagination } from 'vant'

Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Search)
Vue.use(Icon)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Toast)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(NavBar)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Col)
Vue.use(Row)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(VanImage)
Vue.use(Popup)
Vue.use(dialog)
Vue.use(Dialog)
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(Sticky)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Stepper)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Pagination )
