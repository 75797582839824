// 封装设置/获取/删除token
const KEY = 'userToken'
// 设置Token
export const setToken = (newToken) => {
  localStorage.setItem(KEY, newToken)
}
// 获取Token
export const getToken = () => {
  return localStorage.getItem(KEY)
}
// 删除Token
export const delToken = () => {
  localStorage.removeItem(KEY)
}
// 设置医院信息
export const setHospitalInfo = (newInfo) => {
  localStorage.setItem('hospital_info', newInfo)
}
// 获取医院信息
export const getHospitalInfo = () => {
  return localStorage.getItem('hospital_info')
}
// 删除医院信息
export const delHospitalInfo = () => {
  localStorage.removeItem('hospital_info')
}
// 设置用户信息
export const setUserInfo = (newInfo) => {
  localStorage.setItem('userInfo', newInfo)
}
// 获取用户信息
export const getUserInfo = () => {
  return localStorage.getItem('userInfo')
}
// 删除用户信息
export const delUserInfo = () => {
  localStorage.removeItem('userInfo')
}
