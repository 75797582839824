import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutIndex from '@/views/LayoutIndex'
import ReportList from '@/views/ReportList'
// import HomePage from '@/views/HomePage'
import DateReport from '@/views/DateReport'
import LoginPage from '@/views/LoginPage'
import DailyReport from '@/views/DailyReport'
import ScanCodePage from '@/views/ScanCodePage'
Vue.use(VueRouter)

const routes = [
  {
    path: '/layout',
    component: LayoutIndex,
    redirect: '/datereport',
    children: [
      // { path: '/home', component: HomePage },
      { path: '/reportlist', component: ReportList },
      { path: '/datereport',name:'datereport', component: DateReport },
      { path: '/dailyreport', component: DailyReport }
    ]
  },
 
  { 
    path: '/', 
    component: LoginPage ,
    redirect: '/login', 
    children: [
      {path: '/login', component: LoginPage}
    ]
  },

  {
    title: '扫码页面',
    name: 'ScanCodePage',
    path: '/ScanCodePage',
    component: ScanCodePage
  },
]

const router = new VueRouter({
  routes
})

export default router
