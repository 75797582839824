<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="barcode"
        name="barcode"
        autofocus
        ref="barcode"
        label="条码号"
        placeholder="条码号"
        :rules="[{ required: true, message: '请填写条码号' }]"
        @keydown="barcodeInp($event)"
      >
        <template #extra >
          <div style="width:40px" @click="scanCode()">
            <van-icon style="float:right" name="scan" size="20px"/>
          </div>
        </template>
      </van-field>
      <van-field
        v-model="pa_number"
        name="pa_number"
        ref="pa_number"
        label="病理号"
        placeholder="病理号"
        :rules="[{ required: true, message: '请填写病理号' }]"
      />
      <van-field
        readonly
        clickable
        label="项目"
        name='project_pk'
        :value="project_name"
        placeholder="选择项目"
        @click="showPickerpro = true"
        :rules="[{ required: true, message: '请填写项目名' }]"
      >
      <template #button>
        <div v-if="suo === 'suoding'" @click.stop @click="suoding()" style="width:40px">
          <img style="width:20px" src="@/assets/suoding.png" alt="">
        </div>
        <div v-if="suo === 'jiesuo'" @click.stop @click="jiesuo()" style="width:40px">
          <img style="width:20px" src="@/assets/jiesuo.png" alt="">
        </div>
      </template>
      </van-field>
      <van-popup v-model="showPickerpro" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPickerpro = false"
          @confirm="onConfirmProject"
        />
      </van-popup>
      <van-field label="送检时间"
        v-model="delivered_time"
        name='delivered_time'
        @click="showPopup()"
        readonly
        placeholder="选择送检时间"
        :rules="[{ required: true, message: '请填写送检时间' }]"
      />
      <van-popup v-model="ShowPickerTime" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择日期"
          @confirm="onConfirmPicker"
          @cancel="ShowPickerTime = false"
        />
      </van-popup>
      <van-field name="count" label="项目数量" >
        <template #input>
          <van-stepper v-model="count" min="1" />
        </template>
      </van-field>
      <van-field name="bodypart_count" label="取材部位数量" >
        <template #input>
          <van-stepper v-model="bodypart_count" min="1" />
        </template>
      </van-field>
      <van-field
        v-model="remark"
        rows="2"
        autosize
        label="备注"
        name="remark"
        type="textarea"
        maxlength="50"
        placeholder="请输入备注"
        show-word-limit
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-dialog v-model="show" title="是否确认提交" show-cancel-button  @confirm="submit" @cancel="cancel"/>
  </div>
</template>

<script>
import {getFormatDate} from '../utils/utils'
import {addData, projectList} from '../api/datareport'
import {getHospitalInfo  } from "@/utils/storage";
export default {
  data () {
    return {
      barcode: '',
      pa_number: '',
      project: false,
      showPickerpro:false,
      project_pk:'',
      ShowPickerTime: false,
      columns: [],
      count: 1,
      bodypart_count:1,
      subtime: false,
      delivered_time:getFormatDate(new Date()),
      currentDate:new Date(),
      remark:'',
      hospital_pk: '',
      project_name: '',
      flag: true,
      suo:'jiesuo',
      show:false
    }
  },
  async created () {
    this.hospital_pk = JSON.parse(getHospitalInfo()).hospital_pk
    const { data: projectData } = await projectList({is_platform: 1})
    projectData.data.forEach((project, index) => {
      this.columns[index] = {
        text: project.name,
        value: project.id
      }
    });
    this.getParams()
  },
  methods: {
    barcodeInp(e) {
      if (e.keyCode === 13) {
        this.$refs.pa_number.focus()
      }
    },

    onConfirmProject(value) {
      this.project_pk = value.value;
      this.project_name = value.text;
      this.showPickerpro = false;
    },

    onConfirmPicker(value) {
      this.delivered_time = getFormatDate(value);
      this.ShowPickerTime = false;
    },

    showPopup() {
      this.ShowPickerTime = !this.ShowPickerTime
    },

     onSubmit (values) {
      this.show = true
      this.values = values
      document.addEventListener('keydown', this.handleKeydown);
    },
    handleKeydown(e) {
    },
    async submit () {
      if (!this.flag) return
      this.flag = false
      this.values.hospital_pk = this.hospital_pk
      this.values.project_pk = this.project_pk
      this.values.is_platform = 1
      if(Date.parse(this.delivered_time)>new Date().getTime()){
        alert('送检时间超过当前日期')
        this.flag = true
        return
      }
      const { data } =await addData(this.values)
      if (data.code === 200 ) this.$toast.success('提交成功')
      this.flag = true
      this.barcode = ''
      this.pa_number = ''
      if (this.suo === 'jiesuo') {
        this.project_name = ''
        this.project_pk = ''
      }
      this.delivered_time = getFormatDate(new Date())
      this.count = 1

      this.remark = ''
      this.$refs.barcode.focus()
    },
    cancel() {
      this.show = false
      document.removeEventListener('keydown', this.handleKeydown);
    },
    scanCode () {
      this.$router.push({
        path: '/ScanCodePage'
        })
    },
    suoding() {
      this.suo = 'jiesuo'
    },
    jiesuo () {
      this.suo = 'suoding'
    },
    getParams() {
      let routerParams = this.$route.params
      if (routerParams.barcode) {
        this.barcode = routerParams.barcode
        this.$refs.pa_number.focus()
      } else {
        this.$refs.barcode.focus()
      }
    }
  }
}

</script>

<style scoped>
  .qr-item{
    /* width: 3.3rem; */
    height: 100%;
    background-size: 120%;
    position: relative;
    overflow: hidden;
    /* border: 1px solid #dae7f6; */
    /* background-color: #f5f5f5; */
  }
  .qr-item-input{
    opacity: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .title  {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14vh;
  }
  .btn{
    width: 50%;
    line-height: 45px;
    text-align: center;
  }
</style>