import axios from 'axios'
import { Toast } from 'vant'
import { getToken } from '@/utils/storage'
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
  // timeout: 5000
})
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers.Authorization = getToken()
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response
}, function (error) {
  // 对响应错误做点什么
  if (error.response) {
    Toast.fail(error.response.data.msg)
  }
  return Promise.reject(error)
})

export default instance
