<template>
  <div class="body">
    <div>
      <van-form @submit="onSubmit">
        <van-field v-model="barcode" name="barcode" label="条码号" placeholder="请输入条码号" />
        <van-field label="起始时间"
        v-model="delivered_timebegin"
        @click="showPopupBegin()"
        name="start_time"
        readonly
        placeholder="选择起始时间"
        />
        <van-popup v-model="ShowPickerTimeBegin" position="bottom">
          <van-datetime-picker
            v-model="start_time"
            type="date"
            title="选择日期"
            @confirm="onConfirmPickerBegin"
            @cancel="ShowPickerTimeBegin = false"
          />
        </van-popup>
        <van-field label="结束时间"
        v-model="delivered_timeend"
        name="end_time"
        @click="showPopupEnd()"
        readonly
        placeholder="选择结束时间"
        />
        <van-popup v-model="ShowPickerTimeEnd" position="bottom">
          <van-datetime-picker
            v-model="end_time"
            type="date"
            title="选择日期"
            @confirm="onConfirmPickerEnd"
            @cancel="ShowPickerTimeEnd = false"
          />
        </van-popup>
        <div style="margin: 16px;">
          <van-row>
            <van-col span=12>
          <van-button round block type="info" @click="init()">刷新</van-button>
            </van-col>
            <van-col span=12>
          <van-button round block type="info" native-type="submit">搜索</van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </div>
    <div class="list">
      <div class="title">
        <h3>上报列表</h3>
      </div>
      <div class="box" v-for="item in projectList" :key="item.id">
        <div class="deletbutton">
          <van-icon name="clear" size="24px"  @click="del(item.id)"/>
        </div>
        <van-row>
          <van-col class="text" span="4">条码号:</van-col>
          <van-col class="text" span="8">{{item.barcode}}</van-col>
          <van-col class="text" span="4">病理号:</van-col>
          <van-col class="text" span="8">{{item.pa_number}}</van-col>
        </van-row>
        <van-row>
          <van-col class="text" span="4">项目名称:</van-col>
          <van-col class="text" span="8">{{item.project_name}}</van-col>
          <van-col class="text" span="4">蜡块数量:</van-col>
          <van-col class="text" span="8">{{item.count}}</van-col>
        </van-row>
        <van-row>
          <van-col class="text" span="4">送检时间:</van-col>
          <van-col class="text" span="8">{{item.delivered_time}}</van-col>
          <van-col class="text" span="6">取材部位数量:</van-col>
          <van-col class="text" span="6">{{item.bodypart_count}}</van-col>
        </van-row>
        <van-row>
          <van-col class="text" span="4">送检医院:</van-col>
          <van-col class="text" span="20">{{item.hospital_name}}</van-col>
        </van-row>
        <van-row>
          <van-col class="text" span="4">备注:</van-col>
          <van-col class="text" span="20">{{item.remark}}</van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import {getFormatDate} from '../utils/utils'
import {ProjectChargeList, delData} from '../api/datareport'
import {getHospitalInfo  } from "@/utils/storage"
import {Dialog} from "vant"
export default {
  data(){
    return{
      delivered_timebegin:'',
      delivered_timeend:'',
      ShowPickerTimeBegin: false,
      ShowPickerTimeEnd: false,
      start_time:new Date() ,
      end_time:new Date(),
      barcode:'',
      projectList:[],
      hospital_pk:''
    }
  },
  async created () {
    this.hospital_pk = JSON.parse(getHospitalInfo()).hospital_pk
    this.init()
  },
  methods: {
    async init() {
      this.delivered_timebegin='',
      this.delivered_timeend='',
      this.barcode= ''
      let { data: projectData } =await ProjectChargeList({hospital_pk:this.hospital_pk,is_platform:1})
      this.projectList = projectData.data.list
    },

     del(id) {
      console.log(123);
      Dialog.confirm({
        title: '是否确认删除',
      })
        .then(async () => {
          const { data } = await delData({id:id,is_platform:1})
          if (data.code === 200 ) this.$toast.success('删除成功')
          this.init()
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },

      onConfirmPickerBegin(value) {
        this.delivered_timebegin = getFormatDate(value);
        this.ShowPickerTimeBegin = false;
      },

      onConfirmPickerEnd(value) {
        this.delivered_timeend = getFormatDate(value);
        this.ShowPickerTimeEnd = false;
      },

      showPopupBegin() {
        this.ShowPickerTimeBegin = !this.ShowPickerTimeBegin
      },

      showPopupEnd() {
        this.ShowPickerTimeEnd = !this.ShowPickerTimeEnd
      },

      async onSubmit (values) {
          values.hospital_pk = this.hospital_pk
          values.is_platform = 1
          let { data: projectData } = await ProjectChargeList(values)
          this.projectList = projectData.data.list
      }
  }
}
</script>

<style scoped>
.body {
  margin-bottom: 50px;
}
.title{
  height: 50px;
}

.box{
  color: #646566;
  border-style: solid;
  border-color: black;
  border-width:1px;
  padding: 10px;
  margin:5px;

}
.text {
  line-height: 30px;
  font-size: 12px;
}
.deletbutton{
  text-align: right;
}
</style>
