<template>
  <div>
    <router-view></router-view>
    <van-tabbar route v-model="active">
      <!-- <van-tabbar-item  to="/home" icon="wap-home-o">首页</van-tabbar-item> -->
      <van-tabbar-item  to="/dailyreport" icon="newspaper-o">日报</van-tabbar-item>
      <van-tabbar-item  to="/reportlist" icon="search">列表</van-tabbar-item>
      <van-tabbar-item  to="/datereport" icon="todo-list-o">上报</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: 0
    }
  }

}
</script>

<style scoped>

</style>
