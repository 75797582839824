<template>
  <div class="body">
    <div>
      <van-form @submit="onSubmit">
        
        <van-field label="起始时间" 
        v-model="delivered_timebegin"
        @click="showPopupBegin()" 
        name="start_time"
        readonly 
        placeholder="选择起始时间" 
        />
        <van-popup v-model="ShowPickerTimeBegin" position="bottom">
          <van-datetime-picker
            v-model="start_time"
            type="date"
            title="选择日期"
            @confirm="onConfirmPickerBegin"
            @cancel="ShowPickerTimeBegin = false"
          />
        </van-popup>
        <van-field label="结束时间" 
        v-model="delivered_timeend"
        @click="showPopupEnd()" 
        name="end_time"
        readonly 
        placeholder="选择结束时间" 
        />
        <van-popup v-model="ShowPickerTimeEnd" position="bottom">
          <van-datetime-picker
            v-model="end_time"
            type="date"
            title="选择日期"
            @confirm="onConfirmPickerEnd"
            @cancel="ShowPickerTimeEnd = false"
          />
        </van-popup>
        <div style="margin: 16px;">
          <van-row>
            <van-col span=12>
          <van-button round block type="info" @click="init()">刷新</van-button>
            </van-col>
            <van-col span=12>
          <van-button round block type="info" native-type="submit">搜索</van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </div>
    <div class="list">
      <div class="title">
        <h3>日报</h3>
      </div>
      <div class="box" v-for="item in projectList" :key="item.id">
        <van-row>
          <van-col class="text" span="4">送检时间:</van-col>
          <van-col class="text" span="6">{{item.delivered_time}}</van-col>
          <van-col class="text" span="4">送检医院:</van-col>
          <van-col class="text" span="10">{{item.hospital_name}}</van-col>
        </van-row>
        <van-row>
          <van-col class="text" span="4">项目名称:</van-col>
          <van-col class="text" span="6">{{item.project_name}}</van-col>
          <van-col class="text" span="4">蜡块总数:</van-col>
          <van-col class="text" span="10">{{item.sum}}</van-col>
        </van-row>
      </div>
    </div>
  </div>  
</template>

<script>
import {getFormatDate} from '../utils/utils'
import {StatisticsDay} from '../api/datareport'
import {getHospitalInfo } from '@/utils/storage'
export default {
  data(){
    return{
      delivered_timebegin:'',
      delivered_timeend:'',
      ShowPickerTimeBegin: false,
      ShowPickerTimeEnd: false,
      start_time: new Date(),
      end_time:new Date(),
      projectList:[],
      StatisticsDay:{},
    }
  },
  async created () {
    this.StatisticsDay.hospital_name = JSON.parse(getHospitalInfo()).hospital_name
    this.StatisticsDay.end_time = getFormatDate(new Date())
    this.StatisticsDay.start_time = getFormatDate(new Date())
    this.init()
  },
  methods: {
    async init() {
      this.delivered_timebegin='',
      this.delivered_timeend='',
      this.StatisticsDay.is_platform=1
      let { data: projectData } =await StatisticsDay(this.StatisticsDay)
      this.projectList = projectData.data.list
    },
    
    onConfirmPickerBegin(value) {
        this.delivered_timebegin = getFormatDate(value);
        this.ShowPickerTimeBegin = false;
      },

      onConfirmPickerEnd(value) {
        this.delivered_timeend = getFormatDate(value);
        this.ShowPickerTimeEnd = false;
      },
  
      showPopupBegin() {
        this.ShowPickerTimeBegin = !this.ShowPickerTimeBegin
      },

      showPopupEnd() {
        this.ShowPickerTimeEnd = !this.ShowPickerTimeEnd
      },
  
      async onSubmit (values) {
        console.log('submit', values)
        values.end_time = values.end_time ? values.end_time : getFormatDate(new Date)
        values.start_time = values.start_time ? values.start_time : getFormatDate(new Date)
        values.hospital_name = this.StatisticsDay.hospital_name
        values.is_platform = 1
        let { data: projectData } = await StatisticsDay(values)
        console.log(projectData);
        this.projectList = projectData.data.list
      }
  }
}
</script>

<style scoped>
.body {
  margin-bottom: 50px;
}
.title{
  height: 50px;
}

.box{
  color: #646566;
  border-style: solid;
  border-color: black;
  border-width:1px;
  padding: 10px;
  margin:5px;

}
.text {
  line-height: 30px;
  font-size: 12px;
}
</style>