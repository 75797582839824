import instance from '@/utils/request'
export const addData = (data) => {
  return instance.post('/wx/data_report/add.json', data)
}
export const delData = (data) => {
  return instance.post('/wx/data_report/delete.json', data)
}
export const projectList = (data) => {
  return instance.post('/wx/data_report/project_list.json',data)
}
export const ProjectChargeList = (data) => {
  return instance.post('/wx/data_report/hospital_project_charge_list.json',data)
}
export const StatisticsDay = (data) => {
  return instance.post('/wx/data_report/statistics_day.json',data)
}