<template>
  <div>
    <van-nav-bar title="账号登录"/>
    <van-form @submit="onSubmit">
      <van-field
        v-model="uname"
        name="uname"
        label="用户名"
        placeholder="用户名"
        :rules="[
          { required: true, message: '请填写用户名' }
        ]"
      />
      <van-field
        v-model="passwd"
        type="password"
        name="passwd"
        label="密码"
        placeholder="密码"
        :rules="[
          { required: true, message: '请填写密码' }
        ]"
      />
      <div style="margin: 16px">
        <van-button block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { login } from '@/api/user'
import { setToken, setHospitalInfo, setUserInfo,getUserInfo } from '@/utils/storage'
export default {
  data () {
    return {
      uname: '',
      passwd: ''
    }
  },
  created() {
    console.log(new Date());
    if (getUserInfo()) {
      let userInfo = JSON.parse(getUserInfo())
      this.uname = userInfo.uname
      this.passwd = userInfo.passwd
    }
  },
  methods: {
    async onSubmit (values) {
      values.is_platform = 1
      const { data } = await login(values)
      if (data.code === 401) return this.$toast.fail(data.msg)
      this.$toast.success('登录成功')
      setToken(data.data.token)
      setHospitalInfo(JSON.stringify({
        hospital_pk:data.data.hospital_pk,
        hospital_name:data.data.hospital_name
        })
      )
      setUserInfo(JSON.stringify({
        uname:this.uname,
        passwd:this.passwd
      }))

      setTimeout(() => {
        this.$router.push('/datereport')
      }, 500)
    }
  }
}
</script>

<style lang="less" scoped>
.link {
  text-align: center;
  font-size: 14px;
  .Login {
    color: #666;
  }
}
</style>
